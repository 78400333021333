$('#intro_slider').slick({
	autoplay: true,
	autoplaySpeed: 15000,
	prevArrow: '#intro_left',
	nextArrow: '#intro_right'
});
$('#news_slider').slick ({
	autoplay: true,
	autoplaySpeed: 15000,
	slidesToShow: 1,
	prevArrow: '#news_left',
	nextArrow: '#news_right'
});
$('#reviews-slider').slick({
	autoplay: true,
	autoplaySpeed: 15000,
	prevArrow: '#reviews_left',
	nextArrow: '#reviews_right'
});



var menu = $("#humburger"),
	nav = $("#nav");


menu.click(function() {
	if (nav.css("display") == "none") {
		nav.css("display", "flex");
		menu.addClass("menu_active");
		nav.animate({
			top: "0px"
		}, 300);
	} else {
		menu.removeClass("menu_active");
		nav.animate({
			top: "-1000px"
		}, 100, function() {
			nav.css("display", "none");	
		});
	}
	
});